import React from 'react'
import "../Styles/HomePage.css"

const Letstalk = (props) => {
  return (
    <div>             
      <div  className='d-flex justify-content-center align-items-center talk-to' style={{cursor:"pointer"}} >
    <span style={{ color: "black" }} className='talk-to-us text-center'>{props?.value}</span>

</div></div>
  )
}

export default Letstalk