import React, { useEffect, useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Styles/HomePage.css"
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "../Components/Services2.json"
import anotherAnimation from "../Components/Chiragreq.json"
import skeltonanimation from "../Components/skelton.json"
import Header from './Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'

import "../Styles/Carcare.css"
import Reuse from './Reuse';
import Footer from './Footer';
import Letstalk from './Lets talk';
import LetsTalk from '../Modals/LetsTalk';
import axios from "axios";
import Swal from 'sweetalert2';


const HomePage = () => {
  const marqueeRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const audioRef = useRef(null);
  const [formData, setFormData] = useState({

    mobile: '',

  });
  const emailBody = `
  <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
      <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Partner Request Response</h4>
    
  
   
      <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Mobile Number:</strong> ${formData.mobile}
      </div>
 
  

    
  </div>
  


`;
  const [Joinus, setSubmit] = useState(false);
  const handleMouseEnter = () => {
    audioRef.current.play()

  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmit(true);
    // support@honc.io
    axios
      .post('https://api.honc.io/admin/chatbot/send-email', {
        to: 'support@honc.io',
        subject: 'Contact us form response from website',
        // body: `
        //   <br />
        //   <h4>Issues: ${formData.issue}</h4>
        //   <h4>City: ${formData.city}</h4>
        //   <h4>Name: ${formData.name}</h4>
        //   <h4>Phone: ${formData.mobile}</h4>
        //   <h4>Email: ${formData.email}</h4>
        //   <h4>Message: ${formData.textmessage}</h4>
        //   <h4>Country: ${formData.countryname}</h4>
        //   <h4>GatedCommunity: ${formData.gatedcommunity}</h4>
        //   <br />`,
        body: emailBody,
      })
      .then((response) => {
        console.log('Email sent successfully:', response.data);

        Swal.fire('Success', 'Your response has been submitted successfully', 'success');

        setFormData({

          mobile: '',



        });
        setSubmit(false);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmit(false);
      });
  };
  // const closeModal = () => {
  //   setShowModal(false);
  //   window.location.reload(); // Refresh the page after closing the modal
  // };
  useEffect(() => {
    
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
    speed: 0.5

  };
  const loadingoptions={
    animationData:skeltonanimation,
    loop:true,
    
  }
  const { View } = useLottie(options);
  const anotherAnimationOptions = {
    animationData: anotherAnimation,
    loop: true,



  };
  const { View: anotherAnimationView } = useLottie(anotherAnimationOptions);
  const{View:skeltonanimationview}=useLottie(loadingoptions);
  useEffect(() => {

  }, []);
  const [isScrolling, setIsScrolling] = useState(true);

  // const handlemouseEnter = () => {
  //   setIsScrolling(false);
  // };

  // const handlemouseLeave = () => {
  //   setIsScrolling(true);
  // };
  function startAnimation() {

    const scrollPosition = window.scrollY;
    const triggerPosition = 1000;

    if (scrollPosition >= triggerPosition) {
      const bikeImg = document.getElementById('Bike-img');
      if (bikeImg) {
        bikeImg.classList.add('Bike-img');

      }
      window.removeEventListener('scroll', startAnimation);
    }
  }

  window.addEventListener('scroll', startAnimation);



  const handledownload = () => {
    // window.open("https://www.onelink.to/honc","_blank")
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {

      window.open("https://www.onelink.to/honc", "_blank");
    } else if (userAgent.includes('android')) {

      window.open("https://www.onelink.to/honc", "_blank");
    } else {

      window.open("https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN", "_blank");
    }
  }



  const handleTouchStart = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = 'paused';
    }
  };

  const handleTouchEnd = () => {
    if (marqueeRef.current) {
      marqueeRef.current.style.animationPlayState = 'running';
    }
  };
  const play = (url) => {
    audioRef.current.play();
  };



  return (



    <section className='Background' >

      <div className=' overflow-hidden p-0' >
        {/* overflow-hidden p-0 */}
        <section className=' container' >

          <Row className=' d-flex ' style={{ paddingTop: "3%" }} >
            <Col lg={6} md={6} sm={12} xs={12} style={{ position: "relative" }} className='d-flex justify-content-center '   >
              <div className='d-flex justify-content-center ' style={{ flexDirection: "column" }}>
                <Row className='mt-2' style={{ display: "flex" }}>
                  <div className="circle-bg" style={{ zIndex: 1 }}></div>
                  <div className="circle-background" style={{ zIndex: 2 }}></div>
                  {/* onTouchStart={handleMouseEnter} autoPlay   onTouchEnd={handleMouseLeave} {isHovered && (
        <audio >
          <source src="./images/carhorn.mp3" id="horn" type="audio/mp3"  ref={audioRef} />
        </audio>
      )} onMouseLeave={handleMouseLeave}   */}
                  <h2 className='honc-heading' style={{ color: "#B7AA98", zIndex: 3, cursor: "pointer" }}>honc /ˈhȯŋk /<span
                  >
                    <img src="./images/Horn.png" alt="Horn" className=" hornimg" onClick={() => play()}
                    />

                    {/* <audio >
          <source src="" id="horn" type="audio/mp3"  ref={audioRef} />
        </audio> */}
                    <audio
                      src="./images/carhorn.mp3"
                      ref={audioRef}
                    ></audio>
                  </span> </h2>
                  <h2 style={{ color: "#B7AA98" }} className='honc-heading'>noun, verb, adjective</h2>
                </Row>
                <Row className='mt-4'>
                  <div className='ms-3 line'></div>
                </Row>
                <Row className='mt-4'>
                  <h6 style={{ color: "#74d077", opacity: "1" }} className='services'>CAR WASH </h6>
                  <h6 style={{ color: "#74d077", opacity: "1", lineHeight: 0.8 }} className='services'>JUMP START</h6>
                  <h6 style={{ color: "#74d077", opacity: "1" }} className='services'>FLAT-TYRE FIX</h6>
                  <h6 style={{ color: "#74d077", opacity: "1", lineHeight: 0.8 }} className='services'>  OIL CHANGES & MORE</h6>
                </Row>
                <Row className='mt-1'>
                  <span style={{ color: "#FFFFFF" }} className='about-honc'> honc to get a CAR CARE EXPERT</span>
                  <p style={{ color: "#FFFFFF" }} className='about-honc1'> at your doorstep in 30 mins.</p>
                </Row>
                <Row style={{ zIndex: 4 }} className="download-row" >
                  <Col lg={6} md={12} >
                  <div className='d-flex justify-content-center align-items-center text-center download-btn' style={{ cursor: "pointer" }} onClick={handledownload}>

                    <span className='download-app' > Download App</span>



                    <span className='d-flex justify-content-center align-items-center'>
                      <img src="./images/download icons.png" className='ms-1 download-icons' />
                    </span>


                  </div>
                  </Col>
                  <Col lg={4} md={12}  className='justify-content-start chatbtn2   chat-btn  d-flex ' >
                
                <div style={{display:"flex",borderRadius:"30px",justifyContent:"center",alignItems:"center",cursor:"pointer"}} className=' chat-with-us-2' >
                    
                    <div className=''>
                        <p style={{color:"#000000"}} className="  chat-with-us3  mt-3"  data-bs-toggle="modal" data-bs-target="#LetsTalkModal" onClick={() => {
                  setShowModal(true);
                }} >Let's talk</p>
                    </div>
                </div>
               
            </Col> 
                </Row>

              </div>
            </Col>

           
             <Col lg={6} md={6} sm={12} xs={12} className=' d-flex justify-content-end align-items-end services-lottie'  >

  
       {View} 
    
    
   
        

           </Col>

          </Row>

        </section>
        <section className=' moment-of-car'>
          <Row style={{ position: "relative" }} id='bikes' className='justify-content-center align-items-center bike-path  '>

            <div style={{}} className='india-honc'>
              <img src="./images/India Love honc.png" className=" love-honc" />
            </div>
            <div className='car-img'>
              <img src='./images/car.png' className="Bike-img" id="Bike-img"></img>
            </div>

            <Col lg={12} >
              <Row className=''>
                <img src="./images/path.png" className='img-fluid'></img>
              </Row>
            </Col>


          </Row>
        </section>
        <section className='container'>
          <Row className='d-flex services-row' style={{}}>
            <Col lg={6} md={6} sm={12} xs={12} className='manage-col'>
              <Row className='manage-row' style={{ display: "flex" }}>
                <h2 style={{ color: "#B7AA98" }} className='Manage-honc'>Manage your car  </h2>
                <h2 style={{ lineHeight: 1, color: "#B7AA98" }} className='Manage-honc'> through honc</h2>
              </Row>
              <Row className='mt-2'>
                <h6 style={{ color: "#74d077" }} className='journey'>Get the best CAR CARE EXPERT</h6>
                <h6 style={{ color: "#74d077" }} className='journey'>to your doorstep in minutes</h6>
              </Row>
              <Row className='mt-3'>
                <p style={{ color: "#B7AA98" }} className='description'>Connecting Car  Owners with reliable and
                  trustworthy auto-care professionals. From everyday
                  Car Wash to on-demand services, honc matches
                  thousands of Car Owners with top rated
                  professionals.</p>
              </Row>
              {/* <br className='break'></br> */}
              <Row className='lets-talk-row'  >
                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LetsTalkModal" onClick={() => {
                  setShowModal(true);
                }} style={{ textDecoration: "none" }}>


                  <Letstalk
                    value="Talk to us" />
                </a>
              </Row>


            </Col>
            <Col lg={6} md={6} sm={12} xs={12} style={{ position: "relative", marginBottom: "-5%", }} >
              <div className='partner-animation'>
                <div className='bg-animation'></div>
                <div className='bg-animation2'></div>
                {anotherAnimationView}
              </div>
            </Col>

          </Row>
        </section>
        <section className='container'>
          <div>
            <Row className='honc-services-content' style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}>



              <Col lg={2} md={3} xs={6} className='car-and-bike-services-row' style={{ display: "flex" }}   >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center text-center'>
                  <div className='mt-3' >
                    <img src="./images/Car-and-bike-wash.png" alt="Car and bike wash" width="90" height="auto"></img>
                  </div>
                  <div className='mt-4 d-flex justify-content-center text-center align-items-center' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", textAlign: "center", justifyContent: "center", lineHeight: 1.3 }} className='our-services'>Car<br></br>Wash</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6} className='justify-content-center' style={{ display: "flex" }} >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center text-center '>
                  <div className='mt-3' >
                    <img src="./images/Car and bike servicing.png" alt="Car and bike Servicing" width="90" height="auto"></img>
                  </div>
                  <div className='mt-4' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", lineHeight: 1.3 }} className='our-services'>Car<br></br>Servicing</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6} className=' justify-content-center' style={{ display: "flex" }}  >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center detailing-services '>
                  <div className='mt-3' >
                    <img src="./images/jumpstart.png" alt="Detailing Services" width="65" height="auto"></img>
                  </div>
                  <div className='mt-4' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", lineHeight: 1.3 }} className='our-services text-center'>Jumpstart <br></br>Service</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6} className='justify-content-center' style={{ display: "flex" }} >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center text-center honc-services'>
                  <div className='mt-3' >
                    <img src="./images/Flattyre.png" alt="Buy and new Insurance" width="65" height="auto"></img>
                  </div>
                  <div className='mt-4' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", lineHeight: 1.3 }} className='our-services'>Flat tyre<br></br>Fix</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6} style={{ display: "flex" }} className='justify-content-center' >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center text-center honc-services'>
                  <div className='mt-3' >
                    <img src="./images/Denting and Painting.png" alt="Denting and Painting" width="50" height="auto"></img>
                  </div>
                  <div className='mt-4' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", lineHeight: 1.3 }} className='our-services'>Denting & <br></br>Painting</p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={3} xs={6} className=' justify-content-center' style={{ display: "flex" }}  >
                <div style={{ width: "151px", height: "183px", borderRadius: "25px", backgroundColor: "#222222", flexDirection: "column" }} className='d-flex justify-content-center align-items-center detailing-services detailing-services2'>
                  <div className='mt-3' >
                    <img src="./images/Detailing services.png" alt="Detailing Services" width="90" height="auto"></img>
                  </div>
                  <div className='mt-4' >
                    <p style={{ color: "#B7AA98", fontSize: "22px", lineHeight: 1.3 }} className='our-services'>Detailing <br></br>Services</p>
                  </div>
                </div>
              </Col>



            </Row>
          </div>
        </section>


        <Col lg={12} >
          <Row className='road3 '>
            <img src="./images/Road3.png" alt="path" className="img-fluid" />

          </Row>
        </Col>

        <Reuse
          heading="Grow your business "
          heading2="on your terms"
          title1="Become part of  "
          title2="your customers' journey"

        />
        <section className='container'>
          <div>

            <Col lg={12} style={{ position: "relative" }}>
              <form onSubmit={handleSubmit} >
                <Row style={{ backgroundColor: "#0D0D0D" }} className='brand-logo d-flex justify-content-center'>
                  <div className='join-a-partner' style={{ position: "absolute", justifyContent: "center", textAlign: "center", display: "flex", zIndex: "1" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                      <p style={{ color: "#FFFFFF" }} className='partner-details'>Share your number and we’ll reach out to you in a jiffy!</p>
                      <div style={{ display: "flex" }} className='justify-content-center align-items-center'>
                        <input type="tel" className="input-field" placeholder="Enter phone number" name="mobile"
                          value={formData.mobile}
                          onChange={e => {
                            const numericValue = e.target.value.replace(/\D/g, '');
                            handleInputChange({ target: { name: 'mobile', value: numericValue } });
                          }}
                          required></input>

                        <div className='join-us ms-3 d-flex justify-content-center align-items-center '>

                          {/* <p className='join-para d-flex pt-3' value={submitting ? 'Submitting...' : 'Submit'} style={{cursor:"pointer"}} {Joinus ? 'Submitting...' : 'Joinus'} >Join us</p> */}
                          <input type="submit" name="" className="submit-btn1 d-flex justify-content-center" value="Join us"
                            disabled={Joinus} />

                        </div>

                      </div>
                    </div>

                  </div>
                </Row>
              </form>
            </Col>

          </div>
        </section>
        <section className='container automobile-marketplace'>
          <Row className=''>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <h4 className='join-honc' style={{ color: "#B7AA98" }}>Join honc to co-create the automobile marketplace</h4>
              </div>
            </div>
          </Row>
        </section>
        <section className='container ' >
          <Row className='' style={{ display: "flex", flex: 1, justifyContent: "space-evenly", marginTop: "4%" }}>
            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>

                <span style={{ color: "#FFFFFF" }} className='partners-count'>6K+</span><br className='partners-break'></br>
                <div>
                  <span style={{ color: "#B7AA98" }} className='our-partners'>Partners</span>
                </div>
              </div>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                <span style={{ color: "#FFFFFF" }} className='partners-count'>15M+</span><br className='partners-break'></br>
                <div className='text-center d-flex justify-content-center align-items-center'>
                  <span style={{ color: "#B7AA98" }} className='our-partners'>Services delivered</span>
                </div>
              </div>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                <span style={{ color: "#FFFFFF" }} className='partners-count'>06</span><br className='partners-break'></br>
                <span style={{ color: "#B7AA98" }} className='our-partners'>Cities</span>
              </div>
            </Col>
          </Row>
        </section>


        <Row className=' cross-path' >
          <img src="./images/car-road-1.png" className='img-fluid' />
        </Row>
        {/* <section className='container'>         
<Row className='d-flex ' style={{}}>
                    <Col lg={6} md={6} sm={12} xs={12} className='manage-col'>
                        <Row className='honc-community-row' style={{ display: "flex" }}>
                            <h2 style={{ color: "#B7AA98" }} className='join-honc-community'>Join </h2>
                            <h2 style={{ lineHeight: 1, color: "#74d077" }} className='hnc-community'>honc Community<span><img src="./images/community.png" className='community-img'/></span></h2>
                        </Row>
                      
                        <Row className='mt-3'>
                       <p style={{color:"#FFFFFF"}} className='together'>We’re all in this together.<br></br> honcers help make each drive better.</p>
                        </Row>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='d-flex justify-content-end align-items-center' >
                
                  <div style={{}} className='turning'>
                  <img src="./images/Turning talk.png" className='Turning-talk'/>
                  </div>
                    </Col>
                   

                </Row> 
           
                
                <Row className='mt-2  every-juncture' style={{}}>
                            <p style={{color:"#B7AA98"}} className='each-other'>Join honcers who are helping each other at every juncture.</p>

                        </Row>
                        </section>  */}
        {/* <section className='container'>
                        <Row style={{marginTop:"5%"}}>
      <div id="carouselExampleAutoplaying1" className="carousel slide carousel-1" data-bs-ride="carousel" >
     
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2000">
            <Row className=' justify-content-md-center'>
            <Col lg={6} md={6} className="carousel-images"> 
                <img
                  src="./images/traffic.png"
                  className="d-block w-100 carousel-image1"
                  alt="..."
               
                />
         
              </Col>
              <Col lg={6} md={5} className="d-flex  justify-content-center carousel-content" style={{ flexDirection: "column" }}>
 
    <Row>
      <h4 className='hate-traffic'>Those who<br className='carousel-break'></br>  hate traffic.</h4>
    </Row>
    <Row className='mt-4'>
      <h6 className='Agree-planning'>Agree that planning is easier<br></br> than dealing with it?</h6>
    </Row>
    <Row className='mt-3'>
      <h6 className='Agree-planning'>Avoid busy roads and headaches.<br></br> Get real-time traffic updates from co-honcers</h6>
    </Row>

</Col>




            </Row>
          </div>
     
          <div className="carousel-item" data-bs-interval="2000">
          <Row>
          <Col lg={6} md={6} className="carousel-images">
  <img
    src="./images/planning.png"
    className="d-block w-100 carousel-image3"
    alt="..."
  />
</Col>

              <Col lg={6} md={5} style={{flexDirection:"column"}} className="traffic-updates d-flex  justify-content-center  carousel-content">
          <Row>
            <h4  className='Potholes'>Because<br className='carousel-break'></br>  Potholes have<br className='carousel-break'></br>  always  sucked.<br className='carousel-break'></br> </h4>
          </Row>
        
          <Row className='mt-3'>
            <h6 className='potholes-desc'>We pledge to work with GHMC to fix these.</h6>
          </Row>
              </Col>
            </Row>
          </div>
          
        </div>
        <div className="carousel-indicators custom-indicators" >
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying1"
            data-bs-slide-to="0"
            className="active "
            aria-current="true"
            aria-label="Slide 1" 
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying1"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
      
        </div>
     
      </div>
    </Row>
    </section> */}
        {/* <section className='container honc-investors'>
               <Row style={{}} className='our-vendors' >
                <div className='vendors d-flex justify-content-center align-items-center'>
                    <div className='vendors d-flex justify-content-center align-items-center' >
               <Col lg={4}    className='d-flex' style={{flexDirection:"row"}} >
                
                <div className='d-flex justify-content-center'style={{flexDirection:"row",}}>
 
  <img src="./images/Customers.png" style={{width:"90%"}}/>
                </div>
               </Col>
               <Col lg={6}    className='d-flex justify-content-center'>
                <div className='d-flex '>
                <p style={{}} className='create-marketplace'><span className='JOIN ' style={{color:"white"}} >Join</span><span style={{color:"#74d077"}} className='join-hnc '> honc</span><span className='marketplace' style={{color:"white"}}> to co-create the automobile marketplace</span></p>
                </div>
               </Col>
               <Col lg={2} className='d-flex justify-content-center align-items-center me-4' >
               <div className='d-flex justify-content-center align-items-center me-2' >
                <div  className='d-flex justify-content-center align-items-center join-us-btn'>
                    <p style={{color:"#0D0D0D"}} className='join-btn d-flex justify-content-center align-items-center mt-3'>Join Us</p>
                </div>
               </div>
               </Col>
               </div>
                </div>
               </Row>
               </section>  */}
        <section className='container investors-mobile mt-5' >
          <Row style={{ marginLeft: "1%", marginRight: "1%" }}   >
            <div className='d-flex' style={{ width: "100%", background: " transparent linear-gradient(90deg, #555555 0%, #2A2E39 100%)", borderRadius: "20px", flexDirection: "column" }}>
              <Col xs={12} sm={12} className='d-flex justify-content-center pt-2'>
                <img src="./images/Customers.png" className='investors-img-logo' />
              </Col>
              <Col xs={12} sm={12} className='d-flex justify-content-center pt-3'>
                <div className='text-center'>
                  <p style={{}} className='join-honc-to-create'><span className='JOIN ' style={{ color: "white" }} >Join</span><span style={{ color: "#74d077" }} className='join-hnc '> honc</span><span className='marketplace' style={{ color: "white" }}> to co-create the automobile marketplace</span></p>
                </div>
              </Col>
              <Col xs={12} sm={12} className='d-flex justify-content-center align-items-center mb-3' >
                <div className='d-flex justify-content-center align-items-center me-2' >
                  <div className='d-flex justify-content-center align-items-center join-us-btn'>
                    <p style={{ color: "#0D0D0D" }} className='join-btn d-flex justify-content-center align-items-center mt-3'>Join Us</p>
                  </div>
                </div>
              </Col>
            </div>



          </Row>
        </section>
        {/* <Row  className='road-2'>
                             <img src="./images/car-road-2.png" alt="path" className='img-fluid'  /> 
                            
</Row> */}
        <section className='container investors-bg'>
          <Row className='mt-5'>
            <h4 style={{ color: "#B7AA98" }} className='honc-heading d-flex justify-content-center'>Proud Partners of</h4>
          </Row>

          <Row style={{}} className='carousel-investors'>
            {/* <div id="carouselExampleAutoplaying1 " className="carousel slide carousel-1" data-bs-ride="carousel" >
     
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2000">
            <Row className=' justify-content-md-center'>
            <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/Everest.png"
                  className="d-block  partners-img"
                  alt="..."
             
               
                />
         
              </Col>
              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/ElectricPe.png"
                  className="d-block partners-img"
                  alt="..."
                
               
                />
         
              </Col>
              <Col lg={3} md={3}sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/DLF.png"
                  className="d-block partners-img"
                  alt="..."
            
               
                />
         
              </Col>


              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/RAAM.png"
                  className="d-block partners-img"
                  alt="..."
              
               
                />
         
              </Col>

            </Row>
          </div>
     
          <div className="carousel-item " data-bs-interval="2000">
            <Row className=' justify-content-md-center'>
           
            <Col lg={3} md={3} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/RMZ.png"
                  className="d-block  partners-img"
                  alt="..."
               
               
                />
         
              </Col>
              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/GMR.png"
                  className="d-block partners-img"
                  alt="..."
             
               
                />
         
              </Col>
              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/Phoenix.png"
                  className="d-block partners-img"
                  alt="..."
               
               
                />
         
              </Col>


              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/My Home.png"
                  className="d-block partners-img"
                  alt="..."
                
               
                />
         
              </Col>

            </Row>
          </div>
          <div className="carousel-item " data-bs-interval="2000">
            <Row className=' justify-content-md-center'>
            <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/Vamsiram.png"
                  className="d-block partners-img"
                  alt="..."
           
               
                />
         
              </Col>
            <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/Everest.png"
                  className="d-block  partners-img"
                  alt="..."
                 
               
                />
         
              </Col>
              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/Vamsiram.png"
                  className="d-block partners-img"
                  alt="..."
               
               
                />
         
              </Col>
              <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center"> 
                <img
                  src="./images/DLF.png"
                  className="d-block partners-img"
                  alt="..."
                
               
                />
         
              </Col>


            

            </Row>
          </div>
        </div>
        <div className="carousel-indicators custom-indicators" >
        <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying1"
            data-bs-slide-to="0"
            className="active "
            aria-current="true"
            aria-label="Slide 1" 
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying1"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleAutoplaying1"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>

        </div>
     
      </div> */}
            <div id="carouselExampleAutoplaying3" className="carousel slide  carousel-1" data-bs-ride="carousel" data-interval="5000"  >

              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="4000">
                  <Row className=' justify-content-md-center'>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Everest.png"
                        className="d-block  partners-img"
                        alt="..."


                      />

                    </Col>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/ElectricPe.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/DLF.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>


                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Raam.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>

                  </Row>
                </div>

                <div className="carousel-item " data-bs-interval="2000">
                  <Row className=' justify-content-md-center'>

                    <Col lg={3} md={3} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/RMZ.png"
                        className="d-block  partners-img"
                        alt="..."


                      />

                    </Col>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/GMR.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Phoenix.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>


                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/My Home.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>

                  </Row>
                </div>
                <div className="carousel-item " data-bs-interval="2000">
                  <Row className=' justify-content-md-center'>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Vamsiram.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>
                     <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Gaido.png"
                        className="d-block  partners-img"
                        alt="..."


                      />

                    </Col>
                    {/* <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/Vamsiram.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>
                    <Col lg={3} md={3} sm={4} xs={6} className="d-flex justify-content-center">
                      <img
                        src="./images/DLF.png"
                        className="d-block partners-img"
                        alt="..."


                      />

                    </Col>  */}




                  </Row>
                </div>






              </div>
              <div className="carousel-indicators custom-indicators" >
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying3"
                  data-bs-slide-to="0"
                  className="active "
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying3"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying3"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>

                {/* <button
         type="button"
         data-bs-target="#carouselExampleAutoplaying2"
         data-bs-slide-to="5"
         aria-label="Slide 6"
       ></button> */}
              </div>
            </div>
          </Row>

        </section>


        <section className='container investors3' style={{ position: "relative", zIndex: 1 }}>
          <p className='our-investors-mobile d-flex justify-content-center text-center'>Our Investors</p>
          <Row className='mt-5' >
            <Col lg={6} md={6} sm={12} xs={12} className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
              <img src="./images/100x.png" alt="Investor 1" className='Investors4' />
              <p style={{ color: "white", textAlign: "center" }} className='vc mt-3'>100X.VC</p>
              <a href="https://www.linkedin.com/company/100x-vc/" target="_blank" style={{ cursor: "pointer" }}><img src="./images/linkedinn.svg" className='mt-2' /></a>

            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className='d-flex justify-content-center align-items-center mb-0' style={{ flexDirection: "column" }}>
              <img src="./images/Dholakia.png" alt="Investor 2" className='Investors4 dholakia ' />
              <p style={{ color: "white", textAlign: "center" }} className='ventures '>DHOLAKIA VENTURES</p>
              <a href="https://www.linkedin.com/company/dholakiaventures/" target="_blank" style={{ cursor: "pointer" }}><img src="./images/linkedinn.svg" className='mt-2' /></a>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center' style={{ marginTop: "%" }}>

            <p style={{ position: "absolute", left: "0px", zIndex: "-1", opacity: 0.1, color: "#b7aa98" }} className='our-investors d-flex justify-content-center'>Our Investors</p>

          </Row>
          <Row className='d-flex investors-bg1'>
            <Col lg={4} md={4} sm={12} xs={12} className='raghunathan d-flex' style={{ flexDirection: "column" }}>
              <img src="./images/Raghunath.png" className='honc-investors' />
              <p style={{ color: "white", textAlign: "center" }} className='investor-name'>Raghunath Anant
                Mashelkar</p>
              <p style={{ color: "white", textAlign: "center" }} className='head'>Former board of director at Reliance, Tata Motors, Hindustan Unilever
              </p>
              <a href="https://www.linkedin.com/in/raghunath-mashelkar-a99a45168/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=in" target="_blank"><img src="./images/linkedinn.svg" /></a>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className='amey' style={{ flexDirection: "column" }}>
              <img src="./images/Amey.png" className='honc-investors' />
              <p style={{ color: "white", textAlign: "center" }} className='investor-name'>Amey
                Mashelkar
              </p>

              <p style={{ color: "white", textAlign: "center" }} className='head'>Head, JioGenNext</p>
              <p className='jio-head'></p>
              <a href="https://www.linkedin.com/in/amey-mashelkar-20239a4/" target="_blank"><img src="./images/linkedinn.svg" /></a>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className='Jasminder' style={{ flexDirection: "column" }}>
              <img src="./images/Jasminder.png" className='honc-investors' />
              <p style={{ color: "white", textAlign: "center" }} className='investor-name'>Jasminder Singh
                Gulati
              </p>
              <p style={{ color: "white", textAlign: "center" }} className='head'>Co-Founder, NowFloats
              </p>
              <p className='jio-head'></p>

              <a href="https://www.linkedin.com/in/gulatisinghj/?originalSubdomain=in" target="_blank"><img src="./images/linkedinn.svg" /></a>
            </Col>
          </Row>
        </section>

        <Col lg={12} >
          <Row className='road3 '>
            <img src="./images/Road3.png" alt="path" className="img-fluid" />

          </Row>
        </Col>
        <section className='container'>
          <Row className='honcing-experience'>
            <Row>
              <Col lg={6} md={6} className="">
                <div className='d-flex ' style={{ flexDirection: "column" }}>
                  <Row>
                    <h4 style={{ color: "#B7AA98" }} className='honc-heading'>Such a honcing’<span><img src="./images/reviews-star.png" className='star'></img></span><br></br><span >experience</span></h4>
                  </Row>
                  <Row>
                    <h6 style={{ color: "#74d077" }} className='journey pt-3'>❤️ by 1 million community</h6>
                  </Row>

                </div>

              </Col>
              <Col lg={6} md={5} style={{

              }} className="ml-auto ">
                <Row className='reviews-section'>
                  <img src="./images/reviews-quote.png" className='quote-img' />
                </Row>

              </Col>
            </Row>
            <div id="carouselExampleAutoplaying2" className="carousel slide  reviews-carousel" data-bs-ride="carousel"  >

              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="4000">
                  <Row >
                    <Col lg={6} md={6} className="">
                      <div className='d-flex ' style={{ flexDirection: "column" }}>

                        <Row className='d-flex'>
                          <img src="./images/Nibani Ghosh.png" className='customer-reviews-img' />
                        </Row>
                      </div>

                    </Col>

                    <Col lg={6} md={5} style={{

                    }} className=" ">

                      <Row className='d-flex'>
                        <p style={{ color: "white" }} className='custom-desc'>After relocating from Delhi to Bangalore, I needed someone reliable to handle my daily car wash. A friend recommended honc, and ever since, we've been relying on their services for our daily car wash. The honc app is packed with features, including assistance for flat tires, jump starts, and vehicle servicing, providing doorstep convenience. Sending best wishes and good luck to honc from Bangalore!
                        </p>
                      </Row>
                      {/* <br className='break-review'></br> */}
                      <Row>
                        <h6 style={{ color: "#74d077" }} className='honc-reviews'>Nabani Ghosh
                        </h6>
                        <p style={{ color: "white" }} className='customer-place'>Resident, SVS Silver Woods, Whitefield</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <Row>
                    <Col lg={6} md={6} className="">

                      <Row>
                        <img src="./images/Lalitha Dutta.png" className='customer-reviews-img' />
                      </Row>
                      
                    </Col>

                    <Col lg={6} md={5} style={{

                    }} className="ml-auto ">

                      <Row className=''>
                        <p style={{ color: "white" }} className='custom-desc'>I can't emphasize enough how the tyre puncture service through the honc app has been a lifesaver for me. The convenience and affordability it offers are remarkable. I wholeheartedly recommend the honc app to anyone seeking a hassle-free solution to their doorstep.
                        </p>
                      </Row>
                      <Row>
                        <h6 style={{ color: "#74d077" }} className='honc-reviews'>
                          Lalitha Dutta
                        </h6>
                        <p style={{ color: "white" }} className='customer-place'> Resident, Lanco Hills</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <Row>
                    <Col lg={6} md={6} className="" >

                      <Row>
                        <img src="./images/Chirag.png" className='customer-reviews-img' />
                      </Row>
 
                    </Col>

                    <Col lg={6} md={5} className="ml-auto " >

                      <Row className=''>
                        <p style={{ color: "white" }} className='custom-desc'>For someone who is a cleanliness freak, it is a dreadful sight to see our in-house car cleaners clean the car with old clothes and without any proper training or tools. Then I decided to try honc, the cleaning partners were very professional and made sure to clean the car from tail to bonnet. I am  thrilled to see my car neat everyday.</p>
                      </Row>
                      <Row>
                        <h6 style={{ color: "#74d077" }} className='honc-reviews'>Chirag N Raval
                        </h6>
                        <p style={{ color: "white" }} className='customer-place'> Resident, My Home Avatar</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              
                <div className="carousel-item" data-bs-interval="4000">
                  <Row>
                    <Col lg={6} md={6} className="">

                      <Row>
                        <img src="./images/susmitha.png" className='customer-reviews-img' />
                      </Row>
                     
                    </Col>

                    <Col lg={6} md={5} style={{

                    }} className="ml-auto ">

                      <Row className=''>
                        <p style={{ color: "white" }} className='custom-desc'>Since 2021, honc services have consistently exceeded our expectations. During the challenging times of the COVID lockdown, when our car's battery gave out, honc came to the rescue with their jump start service, conveniently accessed through their app.
                          Not only have we benefited from their timely assistance, but many residents in our apartment have also turned to the honc app for daily car and bike cleaning. The affordability of their pricing and the promptness of their service make honc a reliable choice.

                        </p>
                      </Row>
                      <Row>
                        <h6 style={{ color: "#74d077" }} className='honc-reviews'>

                          Sushmitha
                        </h6>
                        <p style={{ color: "white" }} className='customer-place'> Resident, My Home Avatar</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <Row>
                    <Col lg={6} md={6} className="">

                      <Row>
                        <img src="./images/Gopinath.png" className='customer-reviews-img' />
                      </Row>
                    </Col>

                    <Col lg={6} md={5} style={{

                    }} className="ml-auto ">

                      <Row className=''>
                        <p style={{ color: "white" }} className='custom-desc'>Car is my prized possession, and I am very particular about my car’s cleanliness. I have always wanted a daily car wash service that would clean the car and can provide other services at our convenience. honc was the answer to all my questions, and I am very satisfied with their overall service.
                        </p>
                      </Row>
                      <Row>
                        <h6 style={{ color: "#74d077" }} className='honc-reviews'>
                          Hridesh Dixit
                        </h6>
                        <p style={{ color: "white" }} className='customer-place'>Resident, SNN Greenbay, Electronic City</p>
                      </Row>
                    </Col>
                  </Row>
                </div>



              </div>
              <div className="carousel-indicators custom-indicators2" >
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying2"
                  data-bs-slide-to="0"
                  className="active "
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying2"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying2"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying2"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleAutoplaying2"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
          
              </div>
            </div>
          </Row>
        </section>
       
        <LetsTalk
          showModal={showModal}
          setShowModal={(val) => setShowModal(val)} />

      </div>

    </section>


  )
}

export default HomePage