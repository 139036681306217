import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import "../Styles/HomePage.css"
import "../Styles/Contact-us.css"
import axios from "axios";
import Swal from 'sweetalert2'
import { useState } from 'react';
import 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import countryData from "../Components/Countries.json"


const LetsTalk = (props) => {



    const [formData, setFormData] = useState({
        issue: '',
        countryname: '',
        city: '',
        name: '',
        mobile: '',
        country: '',
        email: '',
        gatedcommunity: '',
        textmessage: '',

    });

    const [submitting, setSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const emailBody = `
      <div style="background-color: #f5f5f5; padding: 20px; font-family: Arial, sans-serif;">
          <h4 style="color: #333; font-size: 18px; margin-bottom: 10px;">Contact Us Form Response</h4>
          <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Issues:</strong> ${formData.issue}
      </div>
 
      <div style="margin-bottom: 10px;">
          <strong style="color: #555;">City:</strong> ${formData.city}
      </div>
      <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Name:</strong> ${formData.name}
      </div>
     
      
      
          <div style="margin-bottom: 10px;">
              <strong style="color: #555;">Mobile Number:</strong>  ${formData.country} ${formData.mobile} 
          </div>
          
  
          <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Email:</strong> ${formData.email}
      </div>
      <div style="margin-bottom: 10px;">
      <strong style="color: #555;">Gated Community:</strong> ${formData.gatedcommunity}
      </div>

      <div style="margin-bottom: 10px;">
          <strong style="color: #555;">Message:</strong>${formData.textmessage}
      </div>
    
  </div>
    

     
  
  
`;



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitting(true);

        // support@honc.io
        axios
            .post('https://api.honc.io/admin/chatbot/send-email', {
                to: 'support@honc.io',
                subject: 'Contact us form response from website',
                // body: `
                //   <br />
                //   <h4>Issues: ${formData.issue}</h4>
                //   <h4>City: ${formData.city}</h4>
                //   <h4>Name: ${formData.name}</h4>
                //   <h4>Phone: ${formData.mobile}</h4>
                //   <h4>Email: ${formData.email}</h4>
                //   <h4>Message: ${formData.textmessage}</h4>
                //   <h4>Country: ${formData.countryname}</h4>
                //   <h4>GatedCommunity: ${formData.gatedcommunity}</h4>
                //   <br />`,
                body: emailBody,
            })
            .then((response) => {
                console.log('Email sent successfully:', response.data);

                Swal.fire('Success', 'Your response has been submitted successfully', 'success')
                document.getElementById("add-manager-close-btn").click()
                setFormData({
                    issue: '',
                    countryname: '',
                    city: '',
                    name: '',
                    mobile: '',
                    country: '',
                    email: '',
                    gatedcommunity: '',
                    textmessage: '',


                });
                setSubmitting(false);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                setSubmitting(false);
            });
    };
    function handleMobile() {
        const mobileNum = document.getElementById("mobile-num");
        mobileNum.style.border = "1px solid black";
    }


    return (
        <section>
            <Container fluid>

                <div className="modal fade" id="LetsTalkModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="addContactModalTitle"
                    aria-hidden="true" >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header" style={{ display: "none" }}>


                            </div>
                            <div className="modal-body lets-talk">
                                <div class="container-fluid">

                                    <div className='row'>
                                        <div className='col-lg-5  modal-img' >


                                        </div>
                                        <div className='col-lg-7 '>
                                            <form onSubmit={handleSubmit}>
                                                <div className='d-flex cross-icon-form'>
                                                    <div className='col-md-8 col-sm-8 col-xs-8 d-flex justify-content-flex-start align-items-center' >

                                                        <h3 className='modal-header'>Let's Talk</h3>
                                                    </div>

                                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" /> */}
                                                    {/* <div className='d-flex col-md-4' style={{ justifyContent: "flex-end" }}>
                                                        <img src="./images/cross-icon.png" className='cross-icon-img btn-close' data-bs-dismiss="modal" aria-label="Close" />
                                                    </div> */}
                                                    <div className='d-flex col-md-4 col-sm-4 col-xs-' style={{ justifyContent: "flex-end",alignItems:"center" }}>
                                                                            <button type="button" className="btn-close " id="add-manager-close-btn" data-bs-dismiss="modal" aria-label="Close"  />
                                                                            </div>
                                                </div>

                                                <div className='row mt-2'>
                                                    <div style={{ paddingLeft: "3.8%", position: "relative" }}  >

                                                        <select name="issue" value={formData.issue} onChange={handleInputChange} class="selectBox1" id="color" required>

                                                            <option value="" selected>Select enquiry type *</option>
                                                            <option value="Complaint">Complaint</option>
                                                            <option value="I need a demo">I need a demo</option>
                                                            <option value="Need a call back">Need a call back</option>
                                                            <option value="General enquiry">General enquiry</option>
                                                            <option value="Carrers:Apply for a job">Carrers:Apply for a job</option>
                                                        </select>



                                                    </div>
                                                </div>
                                                <div className='row mt-3' style={{ paddingLeft: "2%" }}>
                                                    {/* <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                                    <input type="text" className="input-text1"  name="countryname" value={formData.countryname} onChange={handleInputChange} placeholder="Country" required></input>
                                                </div> */}
                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                                        <input type="text" className="input-text1 input-city" name="city" value={formData.city}
                                                            onChange={handleInputChange} placeholder="City" required></input>
                                                    </div>
                                                </div>
                                                <div className=' row name-input1' style={{ paddingRight: "2%" }}>
                                                    <input type="text" className="input-text-name1 red-star" name="name" value={formData.name}
                                                        onChange={handleInputChange} placeholder="Name " required ></input>

                                                </div>
                                                <div className='row' style={{ paddingLeft: "2%", paddingTop: "3%", display: "flex", flex: 1, justifyContent: "space-evenly" }}>
                                                    <div className='col-lg-12 col-md-12 col-sm-6 col-xs-6' >




                                                        <div className="rounded-input telephone-num" id="mobile-num" style={{ display: "flex",border: " 1px solid #707070" }} onClick={handleMobile}>

                                                            <div className="country-code-dropdown" style={{ border: "none" }}>
                                                                {/* <select name="country" value={formData.country} className='countrycode'   onChange={handleInputChange}  id="color" style={{border:"none"}} required >
                                                    <option value="" selected>+91 (IN) </option>
  <option value="+1">+1 (US)</option>
  <option value="+44">+44 (UK) </option>
  <option value="+971">+971 (UAE)</option>

                                                    </select> */}
                                                                <select name="country" value={formData.country} onChange={handleInputChange} className='countrycode' style={{ border: "none" }} required>
                                                                    <option value="+91">91 (IN) </option>
                                                                    {countryData.map((country, index) => (
                                                                        <option key={index} value={country.code}>
                                                                            {country.code}  ({country.abbreviation})
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                            </div>
                                                            <div className='separator'>|</div>
                                                            <input
                                                                type="tel"
                                                                id="phone"
                                                                placeholder="Enter your phone number *"
                                                                className='mobile-num'
                                                                style={{ border: "none" }}
                                                                name="mobile"
                                                                value={formData.mobile}
                                                                onChange={e => {
                                                                    const numericValue = e.target.value.replace(/\D/g, '');
                                                                    handleInputChange({ target: { name: 'mobile', value: numericValue } });
                                                                }}
                                                                required
                                                            />


                                                        </div>
                                                    </div>



                                                    <div className='col-lg-12 col-md-12 col-sm-6 col-xs-6 input-email2'>
                                                        <input type="email" className="input-text1 input-city " name="email" value={formData.email}
                                                            onChange={handleInputChange} placeholder="Enter email id" ></input>
                                                    </div>
                                                </div>

                                                <div className=' row name-input1' >
                                                    <input type="text" className="input-text-name-gated" name="gatedcommunity" value={formData.gatedcommunity} onChange={handleInputChange} placeholder="Gated Community Name" ></input>
                                                </div>
                                                <div className='review-text row'>
                                                    <textarea id="w3review" name="textmessage" value={formData.textmessage} onChange={handleInputChange} className='w3review' placeholder='write message' rows="4" cols="50" ></textarea>
                                                </div>
                                                <div style={{ paddingLeft: "5%", paddingTop: "3%", paddingRight: "5%", paddingBottom: "10%" }} className='row' >
                                                    <input type="Submit" className="submit-btn1 d-flex justify-content-center " id="" value={submitting ? 'Submitting...' : 'Submit'} 
                                                        disabled={submitting} />
                                                </div>



                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>

        </section>

    )
}

export default LetsTalk