import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Carcare from './Carcare';
import { Link,useLocation } from 'react-router-dom';
import "../Styles/HomePage.css"
import { useState,useEffect } from 'react';
import "../Styles/Carcare.css";



function Header() {
  // const [isActive, setIsActive] = useState(false);
  // const handleNavLinkClick = () => {
  //   // Set the link as active when it is clicked
  //   setIsActive(true);
  // };
    const badgecolor={
        backgroundColor:"#E03E55",
        fontSize:"12px",
      padding:"5px",
      letterSpacing:"1.5px",
      marginLeft:"5px"
     
       
    }
    useEffect(() => {
      // 👇️ scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => {
      setIsNavCollapsed(!isNavCollapsed);
    };
    const location = useLocation();

    useEffect(() => {
      const element = document.getElementById('top');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo(0, 0);
      }
    }, [location.pathname]);
  
    
   
  return (
  //  <section style={{backgroundColor:" #000000"}} className='sticky-top navbar-fields overflow-hidden p-0' > 
  //   <div className='container'>
  //   <Navbar collapseOnSelect expand="lg" className='sticky-top navbar-fields '  >
     
  //        <Navbar.Brand className='brand-logo '  onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }}  ><Link to="/">
  //        <img src="./images/logo.svg" className='me-4 honc-res-logo'></img></Link>  </Navbar.Brand>
  //       <Navbar.Toggle aria-controls="responsive-navbar-nav nav-icon2"  onClick={handleNavCollapse}  />
  //       <Navbar.Collapse id="responsive-navbar-nav">
  //       <Nav variant="underline" activeKey={location.pathname} className={`d-flex  navheader ${isNavCollapsed ? "animate__fadeOutLeft" : "animate__fadeInRight"}`} style={{justifyContent: "space-between", flex: 1}}  >

       
  //           <Nav.Link    href ="/car-care" style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none"}} className='CarCare 'onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }} >Car Care</Nav.Link>
  //           <Nav.Link    href="/be-a-partner"  style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none"}} className='Be-a-Partner'  onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }}>Be a Partner</Nav.Link>
          
  //           <Nav.Link  className=' d-flex'   href="/shecrew" style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none", marginBottom: 0}}  onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }}>
  //               <div style={{color:"#B7AA98",fontSize:"18px",opacity:"1",flexDirection:"row",textDecoration:"none", marginBottom: 0}} className='d-flex justify-content-center align-items-center sheCrew'>
  //                 <span className='SheSquad'>SheCrew</span>
               
  //                <Stack direction="horizontal"   >
  //               <span className="badge rounded-pill text-center align-items-center franchise " style={badgecolor} >
  //    APPLY
  //     </span>
  //       </Stack>
  //       </div>
     
  //           </Nav.Link>
  //           <Nav.Link className='logo-img2' style={{textDecoration:"none",border:"none"}} href="/"  onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'}) ;
  //       }}><img src="./images/logo.svg" className=' log-img2' style={{width:"125px",height:"43px",flex:2,}} ></img></Nav.Link>
  //           <Nav.Link    href="/team" style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none"}} className='about' onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }}   >Team</Nav.Link>
  //           <Nav.Link   id="top" href="/download" style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none"}} className='download' onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }} activeStyle={{ color: "blue" }}>Download</Nav.Link>
  //           <Nav.Link    href ="/contactus" style={{color:"#B7AA98",fontSize:"18px",opacity:"1",textDecoration:"none"}}className='contact' activeStyle={{ color: "blue" }}  onClick={() => {
  //         window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  //       }}>Contact Us</Nav.Link>
        
      
  //           {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
  //             <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.2">
  //               Another action
  //             </NavDropdown.Item>
  //             <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
  //             <NavDropdown.Divider />
  //             <NavDropdown.Item href="#action/3.4">
  //               Separated link
  //             </NavDropdown.Item>
  //           </NavDropdown> */}
            
  //         </Nav>
        
  //       </Navbar.Collapse>
     
  //   </Navbar>

  //   </div>
  //   </section>
  <section style={{ backgroundColor: "#000000" }} className='sticky-top navbar-fields overflow-hidden p-0'>
  <div className='container'>
    <Navbar collapseOnSelect expand="lg" className='sticky-top navbar-fields' >

      <Navbar.Brand className='brand-logo' onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }}><Link to="/">
          <img src="./images/logo.svg" className='me-4 honc-res-logo'></img></Link> </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav nav-icon2" onClick={handleNavCollapse} />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav variant="underline" activeKey={location.pathname} className={`d-flex  navheader ${isNavCollapsed ? "animate__fadeOutLeft" : "animate__fadeInRight"}`} style={{ justifyContent: "space-between", flex: 1 }} >

          <div className="d-flex align-items-center">
            <Nav.Link className='logo-img2' style={{ textDecoration: "none", border: "none" }} href="/" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>
              <img src="./images/logo.svg" className=' log-img2' style={{ width: "125px", height: "43px", flex: 2, }} ></img>
            </Nav.Link>
          </div>

          <div className="d-flex  mobile-header" >
            <Nav.Link href="/team" style={{ color: "#B7AA98", fontSize: "18px", opacity: "1", textDecoration: "none", marginRight: "60px" }} className='about' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} >Team</Nav.Link>
            <Nav.Link id="top" href="/download" style={{ color: "#B7AA98", fontSize: "18px", opacity: "1", textDecoration: "none", marginRight: "60px" }} className='download' onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }} activeStyle={{ color: "blue" }}>Download</Nav.Link>
            <Nav.Link href="/contactus" style={{ color: "#B7AA98", fontSize: "18px", opacity: "1", textDecoration: "none" }} className='contact' activeStyle={{ color: "blue" }} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>Contact Us</Nav.Link>
          </div>

        </Nav>
      </Navbar.Collapse>

    </Navbar>
  </div>
</section>
  );
}

export default Header;