import React from 'react'
import "../Styles/HomePage.css"
import "../Styles/Meethoncers.css"
import { Row, Col } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';

const Meethoncers = () => {
    const button = useNavigate()
    const navigate2 = () => {
        button('/applynow')
    }
    return (
        <div className='Background '>
            <div className='honcers-desk'>
                <section className='container'>


                    <div className='d-flex justify-content-center align-items-center row p-5'>
                        <div className='honcers-bg3 col-lg-4 col-md-6 pl-2 honcer-details2'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name'>Rahul</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "#2A2E3F" }}>ACCELERATOR</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "#2A2E3F" }}>(ALWAYS ON THE GO)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/rahul-marwar/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>

                        <div className='honcers-bg2 col-lg-4 col-md-6'>
                            <div className='d-flex justify-content-center honcers-details honcer-details2'>
                                <p className='honcers-name'>Viraji</p>
                            </div>
                            <div className='d-flex justify-content-center honcer-details2' >
                                <p className='honcers-desc1' style={{ color: "#2A2E3F" }}>STEERING</p>
                            </div>
                            <div className='d-flex justify-content-center honcer-details2'>
                                <p className='honcers-desc2' style={{ color: "#2A2E3F" }}>(DRIVING FORWARD)</p>
                            </div>
                            <div className='d-flex justify-content-center honcer-details2'>
                                <a href="https://www.linkedin.com/in/virajikondapally/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>

                        <div className='honcers-bg col-lg-4 col-md-6'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name'>Niranjan</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "#2A2E3F" }}>ENGINE</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "#2A2E3F" }}>(LIFE OF THE COMPANY)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/niranjanseelam/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='container honcers-row'>

                    <div className='d-flex honcers2 row' style={{ flexDirection: "row" }}>


                        <div className='honcers-bg5 col-lg-3 col-md-6 col-sm-3'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Gunakanth</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "white" }}>SUSPENSION</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(COMFORT)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/guna-kanth-8ba34a156/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>
                        <div className='honcers-bg6 col-lg-3 col-md-6 col-sm-3'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Ganesh</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "white" }}>ARMREST</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(SUPPORT)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/ganesh-suravarapu-90b344217/" target="_blank">   <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>

                        <div className='honcers-bg7 col-lg-3 col-md-6 col-sm-3'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Sravika</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "white" }}>FUEL GAUGE</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(ENERGY BOMB)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/sravika-reddy-02013a1b2/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>
                        <div className='honcers-bg8 col-lg-3 col-md-6'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Maneesha</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "white" }}>GEAR</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(GROWTH MASTER)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/maneesha-reddy-56b15b203/" target="_blank">  <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div>


                        {/* <div className='honcers-bg35 col-lg-3 col-md-6'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Saif</p>
                            </div>
                            <div className='d-flex justify-content-center ' >
                                <p className='honcers-desc1' style={{ color: "white" }}>ANTENNA</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(SIGNAL SAIFER)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/saifuddinsyed8/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                            </div>
                        </div> */}
                        <div className='col-lg-3  col-md-6 honcers-bg11'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Prem</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SUN VISOR</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2 text-center' style={{ color: "white" }}>(VISION COMFORT)</p>
                            </div>
                            <div className='d-flex justify-content-center' style={{ marginTop: "-4%" }}>
                                <a href="https://www.linkedin.com/in/premkumar-manda/" target='_blank'>  <img src="./images/linkedinn.svg" /> </a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg12'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Sufiyan</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>TAIL LIGHT</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(HAWKEYE)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/sufiyan-baig-731850167/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg15'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Uday</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SUNROOF</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(EXTROVERT)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/uday-balda-273140242/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>


                        <div className='col-lg-3  col-md-6 honcers-bg14'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Kurmaiah</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>WHEELS</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(WORKAHOLIC)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/kurmaiah-bogini-794179242/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>

                        <div className='col-lg-3  col-md-6 honcers-bg16'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Sai</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SEATBELT</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(QUALITY CHECKER)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/sai-kondapalli-054163243/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg17'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Nithin</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>BRAKE</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(GIVES A REALITY CHECK)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/nithin-reddy-00557a17/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg18'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Chandrakanth</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>MIRROR</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(AN HONEST MIND)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/d-bhanuchandrakanth-27b2b7218/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg19'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Rohit</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>DASHBOARD</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(MULTITASKER)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/rohit-kumar-1120bb92/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg20'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Yugander</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>FUEL TANK</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(FINANCIAL WHIZ)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/yugander-babu-111767b1/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg21'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Snehitha</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SPARK PLUG</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(SPARKING SPEED)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/snehitha-thadakamalla-850586211/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg22'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Bhanu</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>BONNET</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(LEVEL-HEADED)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/murarishetty-bhanuteja-1a04b5141/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg23'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Dhanarjana M</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>HORN</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(PARTY ANIMAL)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/dhanuarjun/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>


                        <div className='col-lg-3  col-md-6 honcers-bg26'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Sooraj</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>CLUTCH</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(NO RUSH)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/sooraj-y-95b14a136/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg27'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Sai kumar</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>RADIATOR</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(GLADIATOR)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/sai-kumar-silaparasetti/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg30'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Jitesh</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>360 CAM</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(VIGILANT EYE)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/hiijitesh/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>

                        <div className='col-lg-3  col-md-6 honcers-bg29'>


                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Afzal</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SUNROOF</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(OPEN TO NEW CHALLENGE)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/afzal-ahmad97592/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>

                        <div className='col-lg-3  col-md-6 honcers-bg32'>

                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Dhananjay</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>SPEAKER</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc2' style={{ color: "white" }}>(BACKEND BARD)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/dhananjay-daundkar-066337171/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>

                        <div className='col-lg-3  col-md-6 honcers-bg31'>
                            <div className='d-flex justify-content-center honcers-details'>
                                <p className='honcers-name' style={{ color: "white" }}>Satyajit</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <p className='honcers-desc1' style={{ color: "white" }}>REFLECTOR</p>
                            </div>
                            <div className='d-flex justify-content-center' >
                                <p className='honcers-desc2 text-center' style={{ color: "white" }}>(FAST LEARNER)</p>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href="https://www.linkedin.com/in/satyajit-ray-00b32a249/" target='_blank' style={{ marginTop: "-4%" }}>  <img src="./images/linkedinn.svg" /></a>
                            </div>

                        </div>
                        <div className='col-lg-3  col-md-6 honcers-bg34'>
                            <div className='d-flex justify-content-center could-be-you'>
                                <p className='honcers-desc1 could-be-you' style={{ color: "white" }}>Could be you</p>
                            </div>


                            <div className='d-flex justify-content-center question-mark '>
                                <img src="./images/questionmark.png" className='honcers-name' style={{ width: "40%" }} />
                            </div>
                            <div className='d-flex justify-content-center' >
                                <button type="button" className="apply-now" onClick={navigate2} >Apply</button>
                            </div>



                        </div>

                    </div>

                </section>

            </div>
            <div className="honcers-mobile">
                <section className='container pt-4'>

                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Rahul.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Rahul</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>ACCELERATOR</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(ALWAYS ON THE GO)</p>


                            <a href="https://www.linkedin.com/in/rahul-marwar/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Viraji.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Viraji</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>STEERING</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(DRIVING FORWARD)</p>


                            <a href="https://www.linkedin.com/in/virajikondapally/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Niranjan.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Niranjan</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>ENGINE</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(LIFE OF THE COMPANY)</p>


                            <a href="https://www.linkedin.com/in/niranjanseelam/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>


                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Gunakanth.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Gunakanth</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SUSPENSION</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(CLEAR FOCUS)</p>


                            <a href="https://www.linkedin.com/in/guna-kanth-8ba34a156/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Ganesh.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Ganesh</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>ARMREST</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(SUPPORT)</p>


                            <a href="https://www.linkedin.com/in/ganesh-suravarapu-90b344217/" target="_blank">   <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Sravika.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Sravika</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>FUEL GAUGE</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(ENERGY BOMB)</p>


                            <a href="https://www.linkedin.com/in/sravika-reddy-02013a1b2/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Maneesha.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Maneesha</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>GEAR</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(GROWTH MASTER)</p>


                            <a href="https://www.linkedin.com/in/maneesha-reddy-56b15b203/" target="_blank">  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>


                    {/* <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Saif.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Saif</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>ANTENNA</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(SIGNAL SAIFER)</p>


                            <a href="https://www.linkedin.com/in/saifuddinsyed8/" target="_blank"> <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div> */}
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Prem.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Prem</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SUN VISOR</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(VISION COMFORT)</p>


                            <a href="https://www.linkedin.com/in/premkumar-manda/" target='_blank'>  <img src="./images/linkedinn.svg" /> </a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Sufiyan.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Sufiyan</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>TAIL LIGHT</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(HAWKEYE)</p>


                            <a href="https://www.linkedin.com/in/sufiyan-baig-731850167/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>

                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Uday.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Uday</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SUNROOF</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(EXTROVERT)</p>

                            <a href="https://www.linkedin.com/in/uday-balda-273140242/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Kurmaiah.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Kurmaiah</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>WHEELS</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(WORKAHOLIC)</p>


                            <a href="https://www.linkedin.com/in/kurmaiah-bogini-794179242/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>

                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Sai .png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Sai</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SEATBELT</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(QUALITY CHECKER)</p>

                            <a href="https://www.linkedin.com/in/sai-kondapalli-054163243/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Nithin.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Nithin</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>BRAKE</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(GIVES A REALITY CHECK)</p>

                            <a href="https://www.linkedin.com/in/nithin-reddy-00557a17/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Chandrakanth.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Chandrakanth</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>MIRROR</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(AN HONEST MIND)</p>

                            <a href="https://www.linkedin.com/in/d-bhanuchandrakanth-27b2b7218/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Rohit.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Rohit</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>DASHBOARD</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(MULTITASKER)</p>

                            <a href="https://www.linkedin.com/in/rohit-kumar-1120bb92/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Yugandhar.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Yugander</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>FUEL TANK</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(FINANCIAL WHIZ)</p>

                            <a href="https://www.linkedin.com/in/yugander-babu-111767b1/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Snehitha.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Snehitha</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SPARK PLUG</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(SPARKING SPEED)</p>

                            <a href="https://www.linkedin.com/in/snehitha-thadakamalla-850586211/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Bhanu.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Bhanu</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>BONNET</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(LEVEL-HEADED)</p>

                            <a href="https://www.linkedin.com/in/murarishetty-bhanuteja-1a04b5141/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Arjun.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Dhanarjana M</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>HORN</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(PARTY ANIMAL)</p>

                            <a href="https://www.linkedin.com/in/dhanuarjun/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>


                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Suraj.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Sooraj</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>CLUTCH</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(NO RUSH)</p>

                            <a href="https://www.linkedin.com/in/sooraj-y-95b14a136/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/SaiKumar.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Sai kumar</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>RADIATOR</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(GLADIATOR)</p>

                            <a href="https://www.linkedin.com/in/sai-kumar-silaparasetti/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>

                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Afzal.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Afzal</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SUNROOF</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(OPEN TO NEW CHALLENGE)</p>

                            <a href="https://www.linkedin.com/in/afzal-ahmad97592/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Jitesh.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Jitesh</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>360 CAM</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(VIGILANT EYE)</p>

                            <a href="https://www.linkedin.com/in/hiijitesh/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Dhanunjay.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Dhananjay</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>SPEAKER</p>


                            <p className='' style={{ color: "white", marginTop: "-6%" }}>(BACKEND BARD)</p>

                            <a href="https://www.linkedin.com/in/dhananjay-daundkar-066337171/" target='_blank'>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>
                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/Satyajit.png" />

                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Satyajit</p>


                            <p className='' style={{ color: "white", marginTop: "-3%" }}>REFLECTOR</p>


                            <p className='text-center' style={{ color: "white", marginTop: "-6%" }}>(FAST LEARNER )</p>

                            <a href="https://www.linkedin.com/in/satyajit-ray-00b32a249/" target='_blank' style={{ marginTop: "-4%" }}>  <img src="./images/linkedinn.svg" /></a>
                        </div>

                    </div>

                    <div className='col-lg-12 d-flex justify-content-center align-items-center ' style={{ marginTop: "13%" }}>
                        <div className='d-flex justify-content-center align-items-center' style={{ flexDirection: "column" }}>
                            <img src="./images/Honcers_mobile/You.png" />
                            <p className='honcers-mobile-name' style={{ color: "white", marginTop: "7%" }} >Could be you?</p>
                            <div className='d-flex justify-content-center  apply-btn' >
                                <button type="button" class="btn mobile-apply" onClick={navigate2} >Apply</button>

                            </div>
                        </div>

                    </div>
                </section>
            </div>








        </div>





    )
}

export default Meethoncers