import React from 'react'
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import {  useNavigate } from 'react-router-dom';
import "../Styles/HomePage.css"


const TermsandConditions = () => {
    const button5=useNavigate()
    const navigate5=()=>
  {
    button5('/')
  }
   
  return (


    <section style={{backgroundColor:"#0D0D0D"}}>
        
        <Container fluid className="p-5">
        <img src="./images/backbutton.png" onClick={navigate5} alt="" className='arrow-img' ></img>
     
            <h1 style={{color:"white",marginTop:"2%"}} className='privacypolicy2'>terms of use</h1>
            <h6 style={{marginTop:"30px",color:"white"}} className='policy-para'>the honc.io website/app is an internet based on-demand auto care service provider owned and operated by
                RaviniTechologies Private limited ("Ravini"), a company incorporated under the laws of India, with its
                registered office at Block B, 4th Floor, Q-Hub Labs,Q-City, Sy.No.109,110,111/2, Nanakramguda,
                Gachibowli, Serlingampally(M), Hyd-500032.
                <br />
                <br />
                through the honc mobile application (“Application”), Ravini Technologies shall provide you ("User")
                services-related information, pricing, availability and bookings for the daily car grooming, car
                washing, car servicing ("Service"). This Service may be availed by the registered Users.
                This User Agreement ("Agreement") sets out the terms and conditions on which Ravini shall provide the
                Services to the User through the Application. These Terms and Conditions (“Terms”, “Terms and
                Conditions”) may be updated or modified by honc from time to time.
                <br />
                <br />
                please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using honc
                website and the honc mobile application operated by Ravini Technologies Private Limited. your access to
                and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms
                apply to all visitors, users, partners and others who access or use the Service. by accessing or using
                the Service, you agree to be bound by these Terms. if you disagree with any part of the terms then you
                may not access the Service.
                <br />
                <br />
                the terms “honc”, "we", "us" or "our" shall mean Ravini Technologies Private Limited.</h6>
<p style={{marginTop:"30px",color:"white"}}className='encourage' >We encourage you to read the Privacy Policy whenever using the honc platform or submitting any personal information to honc.</p>
<Accordion className='rounded-2' >
      <Accordion.Item eventKey="0" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header' style={{color:"white"}} >content</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
            <div>

    <p className='collect1'>our Service allows you to post, link, store, share and otherwise make available certain
                        information, text, graphics, videos, or other material ("Content"). you are responsible for the
                        content posted. we reserve the right to remove any content from our site for any reason.</p>
    </div>
   
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="1" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >links to other websites</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p>                our Service may contain links to third­-party web sites or services that are not owned or
                        controlled by Ravini Technologies Private Limited and has no control over, and assumes no
                        responsibility for, the content, privacy policies, or practices of any third party web sites or
                        services. you further acknowledge and agree that Ravini Technologies Private Limited shall not
                        be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
                        caused by or in connection with the use of or reliance on any such content, goods or services
                        available on or through any such web sites or services.
    </p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="2" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >cancellation of bookings</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>   If the service is cancelled by the User for any reason what-so-ever, the cancellation charges
                        shall apply.
                        <br />
                        If the service is cancelled by honc for any reason what-so-ever, User shall be given a refund or
                        rebate as required.</p>
   
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="3" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >changes</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>we reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                        revision is material we will try to provide at least 10 (change this) days' notice prior to any
                        new terms taking effect. what constitutes a material change will be determined at our sole
                        discretion. </p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="4" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >pricing & payment terms</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p  className='collect1'>the prices are decided by honc based on the category of the car (Refer to “type of vehicles”
                        section of the application). booking of the services is on a monthly basis or as per the options
                        made available
                        in this model, the total booking amount is paid by the User at the beginning of availing
                        service. such total booking amount includes the basic fare, taxes (GST 18%), service fees as may
                        be charged on behalf of the actual service provider, and any additional booking fee or
                        convenience fee charged by honc. All payments by the Users for the respective services availed
                        must be carried out online through the application within the stipulated time period allowed.
                        The worker shall not accept any payments from the Users.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="5" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className="intellectual property"  style={{color:"white"}} >intellectual property</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>unless otherwise stated, the copyright, database right and other intellectual property rights in
                        honc (including without limitation all content, materials and technology used or appearing or
                        transmitted through it) belongs to us or our licensors. All rights are reserved.</p>
        </Accordion.Body>


      </Accordion.Item>
      <Accordion.Item eventKey="6" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'style={{color:"white"}}>applicable law</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>these Terms and Conditions will be subject to the laws of Hyderabad, India and the courts shall
                        have jurisdiction to resolve any disputes between us.</p>
        </Accordion.Body>


      </Accordion.Item>
      {/* <Accordion.Item eventKey="7" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >refund policy</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'>honc shall not be liable for any delays or failures in performance resulting from acts beyond
                        its reasonable control including, without limitation, acts of God, acts of war or terrorism,
                        pandemic or epidemic, shortage of supply, breakdowns or malfunctions. Considering the act of God
                        events, you have the option either to reschedule the session or you will be entitled to obtain a
                        refund of your wallet points.
                        <br />
                        <br />
                        Service cancellation refund will be processed to the customer through honc pocket or their
                        respective mode of payment of the service within 48hrs of cancellation request received..</p>
        </Accordion.Body>


      </Accordion.Item> */}
      <Accordion.Item eventKey="8" className='mt-2'>
        <Accordion.Header className='header3 rounded-2' ><h3 className='privacy-header'  style={{color:"white"}} >functionality</h3></Accordion.Header>
       
        <Accordion.Body className="rounded-3 " >
  
    <p className='collect1'> the platform may be under constant upgrades, and some functions and features may not be fully
                        operational.
                        <br />
                        <br />
                        In case of any typographical errors, inaccuracies or omissions on our website or application
                        related to products, services, promotions, offers and availability of products and/or services.
                        we reserve the rights to correct such errors</p>
        </Accordion.Body>


      </Accordion.Item>
 
      </Accordion>
        </Container>
    </section>
  )
}

export default TermsandConditions