import React from 'react'
import "../Styles/HomePage.css"
import "../Styles/Applyjob.css"
import LetsTalk from '../Modals/LetsTalk'
import { useNavigate } from 'react-router-dom'

const Applyjob = (props) => {
    const button=useNavigate()
    const apply=useNavigate()
    const navigateteam=()=>
    {
        button('/team')
    }
    const Applyforjob=()=>
    {
        props?.setShowApplyJobForm(true);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        // apply("/ApplyforJob")
    }
  return (
    <section  >

    <div  className=' overflow-hidden p-0' >
        <div style={{backgroundColor:"#14171f"}}>

        <section className=' container ' style={{padding:"2%"}} >
        <img src="./images/applyjob.png" onClick={navigateteam} alt="" className='arrow-img' ></img>
            <h4 className='full-stack' style={{color:" rgb(116, 208, 119)"}}>Full stack developer - Node Js</h4>
            <h5 className='honc-location' style={{color:" white"}}>Hyderabad</h5>
            <h6 className='full-time' style={{color:" white"}}>Full Time</h6>
            <p className='team-honc' style={{color:"rgb(183, 170, 152)"}}>Team honc is looking for a hands-on engineer who is motivated to drive innovation in full-stack product development, with passion for delivering products end to end, from ideation to implementation, while relentlessly improving performance, scalability, and maintainability.
Responsibilities will include to understand business problems and solve them using technology tools and automation.</p>
<p className='requirements' style={{color:"rgb(183, 170, 152)"}}>Requirements:</p>
<p className='nodejs mt-4' style={{color:"rgb(183, 170, 152)"}}>▶ Technically Hands-on, prior experience with scalable Architecture</p>

<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}> ▶ 2+ years of software engineering and product delivery experience, with strong background in algorithms object oriented programming</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Proficiency with JavaScript</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Proficient understanding of Knowledge of Node.js and frameworks such as Express, StrongLoop, etc</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Experience in React Native, Reactjs, redux</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Experience with incorporating front-end components with end points of scalable web services</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Experience with MongoDB and MySQL</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Experience with RabbitMQ, Queueing Processes, etc.</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Good understanding of asynchronous request handling and functional programming i.e. Callbacks, Promises</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Knowledge of full software development cycle from brainstorming to design, implementation through release</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Proficient understanding of code versioning tools, such as Git</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Strong skills of AWS deployment</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Passion for products, empathy for users, and aspiration to make big impact</p>
<p className='nodejs' style={{color:"rgb(183, 170, 152)"}}>▶ Understanding of Postman</p>

<p className='tech '>If you are passionate to solve day to day challenges with technology and have a high aptitude to learn
and grow by the day, please apply now!</p>
<div className='d-flex justify-content-end apply-now-btn'>
    <div>
    <div  className='d-flex justify-content-center align-items-center talk-to mb-2' >
    <span style={{ color: "black",cursor:"pointer" }} className='talk-to-us  text-center justify-content-center align-items-center  talk-to-text' onClick={Applyforjob}>Apply Now</span>
    </div>
    
    </div>

</div>
            </section>
            </div>
           
<div style={{backgroundColor:"#1e1e1e"}}>
            <section className=' container ' style={{padding:"2%"}} >
            <h4 className='full-stack' style={{color:" rgb(116, 208, 119)"}}>Front End Developer - React Native</h4>
            <h5 className='honc-location' style={{color:" white"}}>Hyderabad</h5>
            <h6 className='full-time' style={{color:"white"}}>Full Time</h6>
            <p className='team-honc'>Team honc is looking for a Front-End Developer in React Native who is motivated to break dimensions in the design and front end experience for our customers. The responsibilities will include end to end app development from code to production deployment. You will work with designers bridging the gap between graphical design and technical implementation, taking an active role on both sides and defining how the application looks as well as how it works</p>
<p className='requirements'>Requirements:</p>
<p className='nodejs mt-4'>▶ At least 2 years experience building mobile apps using React Native</p>

<p className='nodejs'> ▶  Experience using designs (PSD, Sketch etc) and converting them to React Native or CSS components</p>
<p className='nodejs'>▶ XCode and Android Studio experience is a must</p>
<p className='nodejs'>▶  Android (Java) and iOS (Swift) background preferred</p>
<p className='nodejs'>▶ Intermediate to advanced level CSS</p>
<p className='nodejs'>▶  Advanced to expert level knowledge of Javascript (TypeScript preferred)</p>
<p className='nodejs'>▶ Experience using Redux/MobX frameworks</p>
<p className='nodejs'>▶ Git and Github, branching, forking and basic commit skills</p>
<p className='nodejs'>▶ Unit testing - with any unit testing framework, Chai etc.</p>
<p className='nodejs'>▶  Prior startup experience in early stage startups is preferred</p>
<p className='nodejs'>▶ Understanding of APIs and API integration - mustt</p>
<p className='nodejs'>▶  Thorough understanding of deploying of apps on appstore and playstore</p>


<p className='tech '>If you are passionate to solve day to day challenges with technology and have a high aptitude to learn
and grow by the day, please apply now!</p>
<div className='d-flex justify-content-end apply-now-btn'>
    <div>
    <div  className='d-flex justify-content-center align-items-center talk-to mb-2' >
    <span style={{ color: "black",cursor:"pointer" }} className='talk-to-us  text-center justify-content-center align-items-center  talk-to-text' onClick={Applyforjob}>Apply Now</span>
    </div>
    
    </div>

</div>
            </section>
            </div>
            </div>
            </section>
  )
}

export default Applyjob